import { json2xml, xml2json } from 'xml-js';
import { logger } from '../../../logger';
import { getReferenceNumberPartLengths } from '../gaeb/utils';
import { findXmlElementByName, textXmlConverter, XmlDocument, XmlElement } from '../xml-converter';
import { cleanReferenceNumber, getItwoMajorWbsMapFromXml } from './utils';

type WBSItem = {
  referenceNumber: string;
  name: string;
};
export const replaceWbsItemsInITwoXml = ({
  iTwoXml,
  wbs
}: {
  iTwoXml: string;
  wbs: {
    oldName: string;
    newName: string;
    items: WBSItem[];
  }[];
}) => {
  const iTwoXmlJson: XmlDocument = JSON.parse(xml2json(iTwoXml, { compact: false, spaces: 2 }));

  const estimateRoot = iTwoXmlJson.elements?.[0];
  if (estimateRoot?.type === 'element' && estimateRoot.elements) {
    const estimate = estimateRoot.elements.find(
      element => element.type === 'element' && element.name === 'Estimate'
    ) as XmlElement | undefined;
    if (estimate?.elements) {
      const majorWbsMap = getItwoMajorWbsMapFromXml(iTwoXmlJson);
      estimate.elements = estimate.elements.filter(
        element => element.type !== 'element' || element.name !== 'WBS'
      );

      wbs.forEach(wbs => {
        const wbsElement = majorWbsMap[wbs.oldName];
        const referenceNumberPartLengths = getReferenceNumberPartLengths({
          items: wbs.items
        });

        if (!wbsElement) {
          logger.error(`WBS item ${wbs.oldName} not found in ITwoXml`);
          return;
        }

        // Update WBS name and description
        const nameWbsElement = findXmlElementByName(wbsElement.elements, 'NameWBS');
        const descrWbsElement = findXmlElementByName(wbsElement.elements, 'DescrWBS');

        if (nameWbsElement) {
          nameWbsElement.elements = [{ type: 'text', text: wbs.newName }];
        }

        if (descrWbsElement) {
          descrWbsElement.elements = [{ type: 'text', text: '' }];
        }

        const wbsItemsElement = findXmlElementByName(wbsElement.elements, 'ITEMS');

        if (!wbsItemsElement) {
          logger.error(`WBS item ${wbs.oldName} has no ITEMS element`);
          return;
        }

        wbsItemsElement.elements = [];

        for (const item of wbs.items) {
          const wbsItem = wbsItemsElement.elements?.find(
            e =>
              e.type === 'element' &&
              e.name === 'WBSItem' &&
              cleanReferenceNumber(
                textXmlConverter.toJson(
                  findXmlElementByName(e.elements, 'NameWBSItem')?.elements?.[0]
                ) ?? ''
              ) === item.referenceNumber
          );

          if (wbsItem) {
            continue;
          }

          wbsItemsElement.elements?.push({
            type: 'element',
            name: 'WBSItem',
            elements: [
              {
                type: 'element',
                name: 'NameWBSItem',
                elements: [
                  {
                    type: 'text',
                    text: formatReferenceNumber(item.referenceNumber, referenceNumberPartLengths)
                  }
                ]
              },
              {
                type: 'element',
                name: 'OutlineSpecs',
                elements: [{ type: 'text', text: item.name }]
              },
              {
                type: 'element',
                name: 'IsNeutralisedCostRisk',
                elements: [{ type: 'text', text: '0' }]
              },
              { type: 'element', name: 'UsesDerivedCoCs', elements: [{ type: 'text', text: '0' }] },
              {
                type: 'element',
                name: 'IsRevenueDeduction',
                elements: [{ type: 'text', text: '0' }]
              },
              {
                type: 'element',
                name: 'IsFixedPriceItem',
                elements: [{ type: 'text', text: '0' }]
              },
              { type: 'element', name: 'IsNoMarkup', elements: [{ type: 'text', text: '0' }] },
              { type: 'element', name: 'URFromSubDesc', elements: [{ type: 'text', text: '0' }] },
              {
                type: 'element',
                name: 'ByManualCharge_E',
                elements: [{ type: 'text', text: '0' }]
              },
              {
                type: 'element',
                name: 'AdvancedAllocation_E',
                elements: [{ type: 'text', text: '0' }]
              },
              { type: 'element', name: 'SPPhase', elements: [{ type: 'text', text: 'Tender' }] }
            ]
          });
        }

        estimate.elements?.push(wbsElement);
      });
    }
  }

  return {
    content: json2xml(JSON.stringify(iTwoXmlJson), { compact: false, spaces: 2 })
  };
};

const formatReferenceNumber = (referenceNumber: string, referenceNumberPartLengths: number[]) => {
  const parts = referenceNumber.split('.');
  const formattedParts = parts.map((part, index) =>
    part.padStart(referenceNumberPartLengths[index]!, ' ')
  );
  return formattedParts.join('.');
};
