import { ExportTable, TableRowDto, ToUploadFile } from '@company/common/types';
import { json2xml, xml2json } from 'xml-js';
import { rowValueToString } from '../../table';
import { findXmlElementByName, XmlDocument, XmlElement, XmlNode } from '../xml-converter';
import { getDefaultGaebXml } from './default-gaeb-xml';
import {
  FieldReferenceIdToFieldId,
  getChildRowsOfRow,
  getFieldReferenceIdToFieldId,
  getReferenceNumberPartLengths,
  removeEndingDot
} from './utils';

export const exportTableAsGaebFile = ({
  table,
  ...rest
}: {
  table: ExportTable;
} & (
  | {
      gaebXml: string;
      projectName: string;
      projectDescription?: string;
    }
  | {
      projectName: string;
      projectDescription: string;
    }
)): ToUploadFile => {
  const gaebXmlString =
    'gaebXml' in rest ? rest.gaebXml : getDefaultGaebXml(rest.projectName, rest.projectDescription);
  let gaebXmlJson: XmlDocument = JSON.parse(
    xml2json(gaebXmlString, {
      compact: false,
      spaces: 2
    })
  );

  updateGaebBoQXml(gaebXmlJson, table, rest.projectName, rest.projectDescription);

  return {
    content: json2xml(JSON.stringify(gaebXmlJson), { compact: false, spaces: 2 }),
    name: `${table.name}.x83`,
    extension: 'x83'
  };
};

const updateGaebBoQXml = (
  gaebXmlJson: XmlDocument,
  table: ExportTable,
  projectName: string,
  projectDescription?: string
): XmlDocument => {
  const gaebElement = findXmlElementByName(gaebXmlJson.elements, 'GAEB');
  const awardElement = findXmlElementByName(gaebElement?.elements, 'Award');
  const boqElement = findXmlElementByName(awardElement?.elements, 'BoQ');
  const projectInfoElement = findXmlElementByName(gaebElement?.elements, 'PrjInfo');

  const fieldReferenceIdToFieldId = getFieldReferenceIdToFieldId({
    fields: table.fields.map(f => ({ id: f.id, referenceId: f.referenceId }))
  });

  const rows = table.rows;
  const boqRow = rows.find(r => r.parentRowId === null);

  if (boqElement) {
    const boqInfoElement = boqElement.elements?.find(
      e => e.type === 'element' && e.name === 'BoQInfo'
    );
    if (!boqInfoElement) {
      throw new Error('BoQInfo element not found');
    }
    boqElement.elements = [
      ...(boqElement.elements?.filter(
        e => e.type !== 'element' || (e.name !== 'BoQBody' && e.name !== 'BoQInfo')
      ) ?? []),
      updateBoqInfoElement({
        table,
        boqInfoElement,
        referenceNumberFieldId: fieldReferenceIdToFieldId.referenceNumber,
        projectName: projectName,
        projectDescription: projectDescription
      }),
      createBoqBodyElement({
        childRows: rows.filter(r => r.parentRowId === boqRow?.id),
        allRows: rows,
        fieldReferenceIdToFieldId
      })
    ];
  }

  updateProjectInfoElement({
    projectInfoElement,
    projectName,
    projectDescription
  });

  return gaebXmlJson;
};

const createBoqBodyElement = ({
  childRows,
  allRows,
  fieldReferenceIdToFieldId
}: {
  childRows: TableRowDto[];
  allRows: TableRowDto[];
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
}): XmlNode => {
  const categoryRows = childRows.filter(row => isCategoryRow(row, fieldReferenceIdToFieldId));
  const itemRows = childRows.filter(row => !isCategoryRow(row, fieldReferenceIdToFieldId));

  const elements: XmlNode[] =
    categoryRows.length > 0
      ? categoryRows.map(row => {
          const nextChildRows = getChildRowsOfRow({ row, rows: allRows });
          return createBoqCtgyElement({
            categoryRow: row,
            childRows: nextChildRows,
            allRows,
            fieldReferenceIdToFieldId
          });
        })
      : [
          createItemListElement({
            childRows: itemRows,
            fieldReferenceIdToFieldId
          })
        ];

  return {
    type: 'element',
    name: 'BoQBody',
    elements
  };
};

const isCategoryRow = (
  row: TableRowDto,
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId
): boolean => row[fieldReferenceIdToFieldId.unit] === '' || !row[fieldReferenceIdToFieldId.unit];

const createBoqCtgyElement = ({
  categoryRow,
  childRows,
  allRows,
  fieldReferenceIdToFieldId
}: {
  categoryRow: TableRowDto;
  childRows: TableRowDto[];
  allRows: TableRowDto[];
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
}): XmlNode => {
  const categoryName = removeEndingDot(
    rowValueToString(categoryRow[fieldReferenceIdToFieldId.shortText])
  );
  const referenceNumber = rowValueToString(categoryRow[fieldReferenceIdToFieldId.referenceNumber]);
  const referenceNumberPart = referenceNumber.split('.').pop();

  return {
    type: 'element',
    name: 'BoQCtgy',
    attributes: {
      ID: categoryRow.id,
      RNoPart: referenceNumberPart!
    },
    elements: [
      {
        type: 'element',
        name: 'LblTx',
        elements: [
          {
            type: 'element',
            name: 'p',
            elements: [
              {
                type: 'element',
                name: 'span',
                elements: [{ type: 'text', text: categoryName }]
              }
            ]
          }
        ]
      },
      createBoqBodyElement({
        childRows,
        allRows,
        fieldReferenceIdToFieldId
      })
    ]
  };
};

const createItemListElement = ({
  childRows,
  fieldReferenceIdToFieldId
}: {
  childRows: TableRowDto[];
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
}): XmlNode => {
  return {
    type: 'element',
    name: 'Itemlist',
    elements: childRows.map(row => {
      return createItemElement({
        itemRow: row,
        fieldReferenceIdToFieldId
      });
    })
  };
};

const createItemElement = ({
  itemRow,
  fieldReferenceIdToFieldId
}: {
  itemRow: TableRowDto;
  fieldReferenceIdToFieldId: FieldReferenceIdToFieldId;
}): XmlNode => {
  const unit = rowValueToString(itemRow[fieldReferenceIdToFieldId.unit]);
  const name = rowValueToString(itemRow[fieldReferenceIdToFieldId.shortText]);
  const description = rowValueToString(itemRow[fieldReferenceIdToFieldId.longText]);
  const descriptionLines = description.split('\n').filter(line => line.trim() !== '');
  const referenceNumber = rowValueToString(itemRow[fieldReferenceIdToFieldId.referenceNumber]);
  const referenceNumberPart = referenceNumber.split('.').pop();

  return {
    type: 'element',
    name: 'Item',
    attributes: {
      ID: itemRow.id,
      RNoPart: referenceNumberPart!
    },
    elements: [
      {
        type: 'element',
        name: 'LumpSumItem',
        elements: [
          {
            type: 'text',
            text: 'Yes'
          }
        ]
      },
      {
        type: 'element',
        name: 'UPBkdn',
        elements: [
          {
            type: 'text',
            text: 'Yes'
          }
        ]
      },
      {
        type: 'element',
        name: 'Qty',
        elements: [
          {
            type: 'text',
            text: '1.000'
          }
        ]
      },
      {
        type: 'element',
        name: 'QU',
        elements: [
          {
            type: 'text',
            text: unit
          }
        ]
      },
      {
        type: 'element',
        name: 'Description',
        elements: [
          {
            type: 'element',
            name: 'CompleteText',
            elements: [
              {
                type: 'element',
                name: 'DetailTxt',
                elements: [
                  {
                    type: 'element',
                    name: 'Text',
                    elements: [
                      {
                        type: 'element',
                        name: 'p',
                        attributes: {
                          style: 'text-align:left;margin-top:0pt;margin-bottom:0pt;'
                        },
                        elements: descriptionLines.flatMap((line, index) => {
                          const descriptionElement = {
                            type: 'element' as const,
                            name: 'span',
                            attributes: {
                              style: 'font-family:Arial;font-size:10pt;Color:rgb(0,0,0);'
                            },
                            elements: [{ type: 'text' as const, text: line }]
                          };

                          if (index < descriptionLines.length - 1) {
                            return [descriptionElement, { type: 'element' as const, name: 'br' }];
                          }

                          return [descriptionElement];
                        })
                      }
                    ]
                  }
                ]
              },
              {
                type: 'element',
                name: 'OutlineText',
                elements: [
                  {
                    type: 'element',
                    name: 'OutlTxt',
                    elements: [
                      {
                        type: 'element',
                        name: 'TextOutlTxt',
                        elements: [
                          {
                            type: 'element',
                            name: 'p',
                            attributes: {
                              style: 'text-align:left;margin-top:0pt;margin-bottom:0pt;'
                            },
                            elements: [
                              {
                                type: 'element',
                                name: 'span',
                                elements: [{ type: 'text', text: name }]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };
};

const updateBoqInfoElement = ({
  table,
  boqInfoElement,
  referenceNumberFieldId,
  projectName,
  projectDescription
}: {
  table: ExportTable;
  boqInfoElement: XmlNode;
  referenceNumberFieldId: string;
  projectName: string;
  projectDescription?: string;
}): XmlNode => {
  if (boqInfoElement.type !== 'element' || boqInfoElement.name !== 'BoQInfo') {
    throw new Error('BoQInfo element not found');
  }

  const referenceNumberPartLengths = getReferenceNumberPartLengths({
    items: table.rows.map(row => ({
      referenceNumber: rowValueToString(row[referenceNumberFieldId])
    }))
  });

  const boqLevelBkdnElements: XmlElement[] = referenceNumberPartLengths.map((length, index) => ({
    type: 'element',
    name: 'BoQBkdn',
    elements: [
      {
        type: 'element',
        name: 'Type',
        elements: [
          {
            type: 'text',
            text: index < referenceNumberPartLengths.length - 1 ? 'BoQLevel' : 'Item'
          }
        ]
      },
      {
        type: 'element',
        name: 'Length',
        elements: [{ type: 'text', text: String(length) }]
      },
      { type: 'element', name: 'Num', elements: [{ type: 'text', text: 'Yes' }] }
    ]
  }));

  return {
    type: 'element',
    name: 'BoQInfo',
    elements: [
      {
        type: 'element',
        name: 'Name',
        elements: [{ type: 'text', text: projectName }]
      },
      {
        type: 'element',
        name: 'LblBoQ',
        elements: [{ type: 'text', text: projectDescription || '' }]
      },
      ...(boqInfoElement.elements?.filter(
        e =>
          e.type !== 'element' || (e.name !== 'BoQBkdn' && e.name !== 'Name' && e.name !== 'LblBoQ')
      ) ?? []),
      ...boqLevelBkdnElements
    ]
  };
};

const updateProjectInfoElement = ({
  projectInfoElement,
  projectName,
  projectDescription
}: {
  projectInfoElement: XmlElement | undefined;
  projectName: string;
  projectDescription?: string;
}) => {
  if (!projectInfoElement) {
    return projectInfoElement;
  }

  projectInfoElement.elements = [
    {
      type: 'element',
      name: 'NamePrj',
      elements: [{ type: 'text', text: projectName }]
    },
    {
      type: 'element',
      name: 'LblPrj',
      elements: [{ type: 'text', text: projectDescription || '' }]
    }
  ];

  return projectInfoElement;
};
