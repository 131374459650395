import { msg } from '@lingui/core/macro';
import { pickColor } from '../color';
import { FieldNumberConfig, FieldType } from '../table';
import { SelectOption } from '../table/select-option';
import { EnumMap } from '../utils/enum-map';

export enum WorkItemTableFieldEnum {
  boqName = 'boqName',
  type = 'type',
  referenceNumber = 'referenceNumber',
  shortText = 'shortText',
  longText = 'longText',
  quantity = 'quantity',
  unit = 'unit'
}

export type WorkItemTableField = keyof typeof WorkItemTableFieldEnum;

export enum WorkItemTableTypeFieldSelectOptionId {
  Category = 'Category',
  Position = 'Position',
  BoQ = 'BoQ'
}
export const WORK_ITEM_TABLE_TYPE_FIELD_SELECT_OPTIONS: SelectOption<WorkItemTableTypeFieldSelectOptionId>[] =
  [
    {
      id: WorkItemTableTypeFieldSelectOptionId.Category,
      value: 'Category',
      color: pickColor('Category')
    },
    {
      id: WorkItemTableTypeFieldSelectOptionId.Position,
      value: 'Position',
      color: pickColor('Position')
    },
    {
      id: WorkItemTableTypeFieldSelectOptionId.BoQ,
      value: 'BoQ',
      color: pickColor('BoQ')
    }
  ] as const;

export type WorkItemTableTypeFieldSelectOption =
  (typeof WORK_ITEM_TABLE_TYPE_FIELD_SELECT_OPTIONS)[number];

export const WORK_ITEM_TABLE_FIELDS: EnumMap<
  WorkItemTableField,
  {
    type: FieldType;
    isEditable: boolean;
    isPrimary: boolean;
    isRagSearchable: boolean;
    selectOptions?: SelectOption<WorkItemTableTypeFieldSelectOptionId>[];
    numberConfig?: FieldNumberConfig;
    isVisible: boolean;
    defaultWidth?: number;
  }
> = {
  boqName: {
    id: 'boqName',
    getLabel: t => t(msg`Boq Name`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  type: {
    id: 'type',
    getLabel: t => t(msg`Type`),
    type: 'SINGLE_SELECT',
    isEditable: false,
    isPrimary: false,
    selectOptions: WORK_ITEM_TABLE_TYPE_FIELD_SELECT_OPTIONS,
    isVisible: false,
    isRagSearchable: false
  },
  referenceNumber: {
    id: 'referenceNumber',
    getLabel: t => t(msg`Reference number`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: true,
    isRagSearchable: false,
    defaultWidth: 85
  },
  shortText: {
    id: 'shortText',
    getLabel: t => t(msg`Short text`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: true,
    isVisible: true,
    isRagSearchable: true,
    defaultWidth: 320
  },
  longText: {
    id: 'longText',
    getLabel: t => t(msg`Description`),
    type: 'LONG_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: true,
    defaultWidth: 180
  },
  quantity: {
    id: 'quantity',
    getLabel: t => t(msg`Quantity`),
    type: 'NUMBER',
    isEditable: false,
    isPrimary: false,
    isVisible: true,
    isRagSearchable: false,
    defaultWidth: 120,
    numberConfig: {
      decimalPlaces: 2
    }
  },
  unit: {
    id: 'unit',
    getLabel: t => t(msg`Unit`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: true,
    isRagSearchable: true,
    defaultWidth: 135
  }
} as const;

export enum CostCalculationTableFieldEnum {
  type = 'type',
  name = 'name',
  key = 'key',
  quantity = 'quantity',
  unit = 'unit',
  costFactor = 'costFactor',
  costPerUnit = 'costPerUnit',
  referenceNumber = 'referenceNumber',
  factor = 'factor',
  factorIsPerformanceFactor = 'factorIsPerformanceFactor',
  flagFixedBudget = 'flagFixedBudget',
  budgetUomItem = 'budgetUomItem',
  budget = 'budget',
  sItemNo = 'sItemNo',
  sItemLSum = 'sItemLSum',
  sItemLSumAbs = 'sItemLSumAbs',
  isDisabled = 'isDisabled',
  compressed = 'compressed',
  sItemReserve = 'sItemReserve',
  spPhase = 'spPhase',
  identifyKey = 'identifyKey',
  currency = 'currency',
  cFactorCoc = 'cFactorCoc',
  qFactorCoc = 'qFactorCoc',
  otherXmlFieldsAsJson = 'otherXmlFieldsAsJson'
}
export type CostCalculationTableField = keyof typeof CostCalculationTableFieldEnum;

export type CostCalculationTypeTableFieldSelectOptionId =
  | 'SubItem'
  | 'CoCDetail'
  | 'CommodityDetail'
  | 'EstTextElement'
  | 'AssemblyDetail';

export const COST_CALCULATION_TYPE_FIELD_SELECT_OPTIONS: SelectOption<CostCalculationTypeTableFieldSelectOptionId>[] =
  [
    {
      id: 'SubItem',
      value: 'SubItem',
      color: pickColor('SubItem')
    },
    {
      id: 'CoCDetail',
      value: 'CoCDetail',
      color: pickColor('CoCDetail')
    },
    {
      id: 'CommodityDetail',
      value: 'CommodityDetail',
      color: pickColor('CommodityDetail')
    },
    {
      id: 'EstTextElement',
      value: 'EstTextElement',
      color: pickColor('EstTextElement')
    },
    {
      id: 'AssemblyDetail',
      value: 'AssemblyDetail',
      color: pickColor('AssemblyDetail')
    }
  ] as const;

export type CostCalculationTypeTableFieldSelectOption =
  (typeof COST_CALCULATION_TYPE_FIELD_SELECT_OPTIONS)[number];

export const COST_CALCULATION_TABLE_FIELDS: EnumMap<
  CostCalculationTableField,
  {
    type: FieldType;
    isEditable: boolean;
    isPrimary: boolean;
    isRagSearchable: boolean;
    selectOptions?: SelectOption[];
    numberConfig?: FieldNumberConfig;
    isVisible: boolean;
    defaultWidth?: number;
  }
> = {
  // SubitemNumber
  referenceNumber: {
    id: 'referenceNumber',
    getLabel: t => t(msg`Reference number`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: true,
    isRagSearchable: false,
    defaultWidth: 85
  },
  name: {
    id: 'name',
    getLabel: t => t(msg`Name`),
    type: 'LONG_TEXT',
    isEditable: false,
    isPrimary: true,
    isVisible: true,
    isRagSearchable: false,
    defaultWidth: 420
  },
  type: {
    id: 'type',
    getLabel: t => t(msg`Type`),
    type: 'SINGLE_SELECT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false,
    selectOptions: COST_CALCULATION_TYPE_FIELD_SELECT_OPTIONS
  },
  quantity: {
    id: 'quantity',
    getLabel: t => t(msg`Quantity`),
    type: 'NUMBER',
    isEditable: true,
    isPrimary: false,
    isVisible: true,
    isRagSearchable: false,
    defaultWidth: 120,
    numberConfig: {
      decimalPlaces: 2
    }
  },
  factor: {
    id: 'factor',
    getLabel: t => t(msg`Factor`),
    type: 'NUMBER',
    isEditable: false,
    isPrimary: false,
    isVisible: true,
    isRagSearchable: false,
    defaultWidth: 130,
    numberConfig: {
      decimalPlaces: 3
    }
  },
  factorIsPerformanceFactor: {
    id: 'factorIsPerformanceFactor',
    getLabel: t => t(msg`Factor Is Performance Factor`),
    type: 'CHECKBOX',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  costFactor: {
    id: 'costFactor',
    getLabel: t => t(msg`Cost Factor`),
    type: 'NUMBER',
    isEditable: false,
    isPrimary: false,
    isVisible: true,
    isRagSearchable: false,
    defaultWidth: 130,
    numberConfig: {
      decimalPlaces: 3
    }
  },
  flagFixedBudget: {
    id: 'flagFixedBudget',
    getLabel: t => t(msg`Flag Fixed Budget`),
    type: 'CHECKBOX',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  budgetUomItem: {
    id: 'budgetUomItem',
    getLabel: t => t(msg`Budget UOM Item`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  budget: {
    id: 'budget',
    getLabel: t => t(msg`Budget`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  unit: {
    id: 'unit',
    getLabel: t => t(msg`Unit`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: true,
    isRagSearchable: false,
    defaultWidth: 150
  },
  sItemNo: {
    id: 'sItemNo',
    getLabel: t => t(msg`SItem No`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  sItemLSum: {
    id: 'sItemLSum',
    getLabel: t => t(msg`SItem LSum`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  sItemLSumAbs: {
    id: 'sItemLSumAbs',
    getLabel: t => t(msg`SItem LSum Abs`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  isDisabled: {
    id: 'isDisabled',
    getLabel: t => t(msg`Disabled`),
    type: 'CHECKBOX',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  compressed: {
    id: 'compressed',
    getLabel: t => t(msg`Compressed`),
    type: 'CHECKBOX',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  sItemReserve: {
    id: 'sItemReserve',
    getLabel: t => t(msg`SItem Reserve`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  spPhase: {
    id: 'spPhase',
    getLabel: t => t(msg`SP Phase`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  // CoC Detail
  key: {
    id: 'key',
    getLabel: t => t(msg`Key`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  identifyKey: {
    id: 'identifyKey',
    getLabel: t => t(msg`Identify Key`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  // URVAlue
  costPerUnit: {
    id: 'costPerUnit',
    getLabel: t => t(msg`Cost Per Unit`),
    type: 'NUMBER',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false,
    numberConfig: {
      decimalPlaces: 2
    }
  },
  currency: {
    id: 'currency',
    getLabel: t => t(msg`Currency`),
    type: 'SHORT_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  cFactorCoc: {
    id: 'cFactorCoc',
    getLabel: t => t(msg`C Factor CoC`),
    type: 'NUMBER',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false,
    numberConfig: {
      decimalPlaces: 3
    }
  },
  qFactorCoc: {
    id: 'qFactorCoc',
    getLabel: t => t(msg`Q Factor CoC`),
    type: 'NUMBER',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  },
  otherXmlFieldsAsJson: {
    id: 'otherXmlFieldsAsJson',
    getLabel: t => t(msg`Other XML Fields`),
    type: 'LONG_TEXT',
    isEditable: false,
    isPrimary: false,
    isVisible: false,
    isRagSearchable: false
  }
};

export type CostCalculationItem = {
  cocId: string;
  subPos: string;
  key: string;
  name: string;
  quantityApproach: string;
  quantity: number;
  unit: string;
  quantityFactor: number;
  costFactor: number;
  costPerUnit: number;
  subItems: CostCalculationItem[];
  subitemNumber: number;
  factor: number;
  factorIsPerformanceFactor: boolean;
  flagFixedBudget: boolean;
  budgetUomItem: number;
  budget: number;
  sItemNo: number;
  sItemLSum: number;
  sItemLSumAbs: number;
  sItemDisabled: boolean;
  compressed: boolean;
  sItemReserve: number;
  spPhase: string;
  nameCoc: string;
  descrCoc: string;
  identifyKey: string;
  currency: string;
  urValue: number;
  cFactorCoc: number;
  qFactorCoc: number;
};

export type PriceCalculationPosition = {
  type: WorkItemTableTypeFieldSelectOptionId;
  referenceNumber: string;
  shortText: string;
  longText: string;
  quantity: number;
  unit: string;
  costCalculation: {
    items: CostCalculationItem[];
  };
};
