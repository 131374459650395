import { z } from 'zod';
import { FileUploadConfig, fileUploadConfigSchema, uploadedFileSchema } from '../files';
import { PieceOfInformationType } from '../piece-of-information';
import { selectConfigSchema } from '../select';

export type AgentInformationRequestDto = {
  id: string;
  informationRequestId: string;
  conversationReferenceId: string;
  inputType: PieceOfInformationType;
  label: string;
  helperText: string | null;
  fileUploadConfig: FileUploadConfig | null;
  isRequired: boolean;
  placeholder: string | null;
};

const formInputValueSchema = z.union([
  z.string(),
  z.array(z.string()),
  uploadedFileSchema,
  z.array(uploadedFileSchema),
  z.boolean(),
  z.null()
]);

const inputTypeSchema = z.enum([
  'FILE',
  'PROJECT',
  'SHORT_TEXT',
  'CHECKBOX_BOOLEAN',
  'CHECKBOX_CARDS'
]);

export const updateAgentInformationRequestValueSchema = z.object({
  referenceId: z.string(),
  inputType: inputTypeSchema,
  value: formInputValueSchema
});

export const agentInformationRequestFieldSchema = z
  .object({
    referenceId: z.string(),
    inputType: inputTypeSchema,
    label: z.string(),
    helperText: z.string().nullish(),
    placeholder: z.string().nullish(),
    isRequired: z.boolean()
  })
  .and(
    z.discriminatedUnion('inputType', [
      z.object({
        inputType: z.literal('FILE'),
        fileUploadConfig: fileUploadConfigSchema
      }),
      z.object({
        inputType: z.literal('PROJECT'),
        selectConfig: selectConfigSchema
      }),
      z.object({
        inputType: z.literal('SHORT_TEXT')
      }),
      z.object({
        inputType: z.literal('CHECKBOX_BOOLEAN')
      }),
      z.object({
        inputType: z.literal('CHECKBOX_CARDS'),
        maxSelected: z.number().nullish(),
        options: z.array(
          z.object({
            label: z.string(),
            description: z.string().nullish(),
            value: z.string()
          })
        )
      })
    ])
  );
export const agentInformationRequestFieldWithValueSchema = agentInformationRequestFieldSchema.and(
  z.object({
    value: formInputValueSchema
  })
);

export type AgentInformationRequestValue = z.infer<typeof formInputValueSchema>;
export type UpdateAgentInformationRequestWithValue = z.infer<
  typeof updateAgentInformationRequestValueSchema
>;
export type AgentInformationRequest = z.infer<typeof agentInformationRequestFieldSchema>;
export type AgentInformationRequestWithValue = z.infer<
  typeof agentInformationRequestFieldWithValueSchema
>;
