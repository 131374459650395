import { json2xml, xml2json } from 'xml-js';
import { logger } from '../../../logger';
import {
  COST_CALCULATION_TABLE_FIELDS,
  CostCalculationTableField,
  CostCalculationTypeTableFieldSelectOptionId,
  WORK_ITEM_TABLE_FIELDS,
  WorkItemTableField
} from '../../../types/data-converter';
import { ToUploadFile } from '../../../types/files';
import { ExportTable, ExportTableField, TableRowDto } from '../../../types/table';
import { removeNullish } from '../../../types/utils';
import { findXmlElementByName, textXmlConverter, XmlDocument } from '../xml-converter';
import { XmlWBSItemEstDetailsConverter } from './converter';
import { WBSItemEstDetailsItem } from './types/cost-estimation';
import {
  cleanReferenceNumber,
  getITwoCostCalculationConvertedRefrenceNumber,
  getItwoMajorWbsMapFromXml
} from './utils';

export const exportTableToITwoCostCalculationFile = ({
  table,
  iTwoXml,
  projectName,
  projectDescription
}: {
  table: ExportTable;
  iTwoXml: string;
  projectName?: string;
  projectDescription?: string;
}): ToUploadFile => {
  let iTwoXmlJson: XmlDocument = JSON.parse(xml2json(iTwoXml, { compact: false, spaces: 2 }));

  const childTable = table.childTable;

  if (!childTable) {
    logger.error(`Table ${table.name} has no child table`);
    throw new Error(`Table ${table.name} has no child table`);
  }

  const fieldReferenceIdToFieldId = table.fields.reduce(
    (acc, field) => {
      if (field.referenceId && field.referenceId in WORK_ITEM_TABLE_FIELDS) {
        acc[field.referenceId as WorkItemTableField] = field.id;
      }
      return acc;
    },
    {} as Record<WorkItemTableField, string>
  );
  const majorWbsMap = getItwoMajorWbsMapFromXml(iTwoXmlJson);

  updateITwoProjectInfo(iTwoXmlJson, projectName, projectDescription);

  if (majorWbsMap) {
    Object.entries(majorWbsMap).forEach(([wbsName, wbs]) => {
      const itemsElement = findXmlElementByName(wbs.elements, 'ITEMS');

      if (!itemsElement) {
        return;
      }

      itemsElement.elements = itemsElement.elements?.map(item => {
        if (item.type !== 'element') {
          return item;
        }

        const referenceNumber =
          textXmlConverter.toJson(
            findXmlElementByName(item.elements, 'NameWBSItem')?.elements?.[0]
          ) ?? '';

        if (!referenceNumber) {
          return item;
        }

        const matchingTableRow = table.rows.find(
          row =>
            row[fieldReferenceIdToFieldId['referenceNumber']] &&
            cleanReferenceNumber(row[fieldReferenceIdToFieldId['referenceNumber']]!.toString()) ===
              cleanReferenceNumber(referenceNumber)
        );

        if (!matchingTableRow) {
          return item;
        }

        const costCalculationFields = childTable.fields;
        const costCalculationRows = childTable.rows.filter(
          row => row.parentTableRowId === matchingTableRow.id
        );

        const xmlWBSItemEstDetailsConverter = new XmlWBSItemEstDetailsConverter();
        const nonEstDetailsElements = (item.elements ?? []).filter(
          element => element.type === 'element' && element.name !== 'EstDetails'
        );
        const wbsItems = convertRowsToWBSItemEstDetails(
          costCalculationFields,
          costCalculationRows,
          null,
          ''
        );

        return {
          ...item,
          elements: [
            ...nonEstDetailsElements,
            xmlWBSItemEstDetailsConverter.toXml({
              items: wbsItems
            })
          ]
        };
      });
    });
  }

  return {
    content: json2xml(JSON.stringify(iTwoXmlJson), { compact: false, spaces: 2 }),
    name: `${table.name}.xml`,
    extension: 'xml'
  };
};

const convertRowsToWBSItemEstDetails = (
  fields: ExportTableField[],
  rows: TableRowDto[],
  parentRowId: string | null,
  parentReferenceNumber: string
): WBSItemEstDetailsItem[] => {
  const rowsMatchingParentId = rows.filter(row => row.parentRowId === parentRowId);

  if (rowsMatchingParentId.length === 0) {
    return [];
  }

  const referenceIdToFieldId = fields.reduce(
    (acc, field) => {
      if (field.referenceId && field.referenceId in COST_CALCULATION_TABLE_FIELDS) {
        acc[field.referenceId as CostCalculationTableField] = field.id;
      }
      return acc;
    },
    {} as Record<CostCalculationTableField, string>
  );

  let referernceNumberPart = 0;

  return rowsMatchingParentId
    .map((row): WBSItemEstDetailsItem | undefined => {
      const type = row[referenceIdToFieldId['type']] as CostCalculationTypeTableFieldSelectOptionId;
      if (type === 'CoCDetail') {
        return {
          type: 'CoCDetail' as const,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          urValue: row[referenceIdToFieldId['costPerUnit']] as number | undefined,
          qFactorCoc: row[referenceIdToFieldId['qFactorCoc']] as number | undefined,
          cFactorCoc: row[referenceIdToFieldId['cFactorCoc']] as number | undefined,
          currency: row[referenceIdToFieldId['currency']] as string | undefined,
          key: row[referenceIdToFieldId['key']] as string | undefined,
          name: row[referenceIdToFieldId['name']] as string | undefined,
          identifyKey: row[referenceIdToFieldId['identifyKey']] as string | undefined,
          isDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'SubItem') {
        referernceNumberPart++;
        const convertedRefrenceNumber =
          getITwoCostCalculationConvertedRefrenceNumber(referernceNumberPart);
        return {
          type: 'SubItem' as const,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          compressed: row[referenceIdToFieldId['compressed']] as boolean | undefined,
          estDetails: {
            items: convertRowsToWBSItemEstDetails(
              fields,
              rows,
              row.id,
              `${parentReferenceNumber}${convertedRefrenceNumber}`
            )
          },
          sItemDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          sItemLSum: row[referenceIdToFieldId['sItemLSum']] as string | undefined,
          text: row[referenceIdToFieldId['name']] as string | undefined,
          unitOfMeasure: row[referenceIdToFieldId['unit']] as string | undefined,
          subItemNumber: `${parentReferenceNumber}${convertedRefrenceNumber}`,
          sItemLSumAbs: row[referenceIdToFieldId['sItemLSumAbs']] as string | undefined,
          sItemNo: `${convertedRefrenceNumber}`,
          sItemReserve: row[referenceIdToFieldId['sItemReserve']] as string | undefined,
          spPhase: row[referenceIdToFieldId['spPhase']] as string | undefined,
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'EstTextElement') {
        return {
          type: 'EstTextElement' as const,
          text: row[referenceIdToFieldId['name']] as string | undefined,
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'CommodityDetail') {
        referernceNumberPart++;
        const convertedRefrenceNumber =
          getITwoCostCalculationConvertedRefrenceNumber(referernceNumberPart);
        return {
          type: 'CommodityDetail' as const,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          currency: row[referenceIdToFieldId['currency']] as string | undefined,
          identifyKey: row[referenceIdToFieldId['identifyKey']] as string | undefined,
          isDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          nameCommodity: row[referenceIdToFieldId['key']] as string | undefined,
          descrCommodity: row[referenceIdToFieldId['name']] as string | undefined,
          urValue: row[referenceIdToFieldId['costPerUnit']] as number | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          estDetails: {
            items: convertRowsToWBSItemEstDetails(
              fields,
              rows,
              row.id,
              `${parentReferenceNumber}${convertedRefrenceNumber}`
            )
          },
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      } else if (type === 'AssemblyDetail') {
        referernceNumberPart++;
        const convertedRefrenceNumber =
          getITwoCostCalculationConvertedRefrenceNumber(referernceNumberPart);
        return {
          type: 'AssemblyDetail' as const,
          nameAssembly: row[referenceIdToFieldId['key']] as string | undefined,
          descrAssembly: row[referenceIdToFieldId['name']] as string | undefined,
          unitOfMeasure: row[referenceIdToFieldId['unit']] as string | undefined,
          identifyKey: row[referenceIdToFieldId['identifyKey']] as string | undefined,
          isDisabled: row[referenceIdToFieldId['isDisabled']] as boolean | undefined,
          quantity: row[referenceIdToFieldId['quantity']] as number | undefined,
          factor: row[referenceIdToFieldId['factor']] as number | undefined,
          factorIsPerformanceFactor: row[referenceIdToFieldId['factorIsPerformanceFactor']] as
            | boolean
            | undefined,
          costFactor: row[referenceIdToFieldId['costFactor']] as number | undefined,
          flagFixedBudget: row[referenceIdToFieldId['flagFixedBudget']] as boolean | undefined,
          budgetUomItem: row[referenceIdToFieldId['budgetUomItem']] as string | undefined,
          budget: row[referenceIdToFieldId['budget']] as string | undefined,
          estDetails: {
            items: convertRowsToWBSItemEstDetails(
              fields,
              rows,
              row.id,
              `${parentReferenceNumber}${convertedRefrenceNumber}`
            )
          },
          otherXmlFieldsAsJson: row[referenceIdToFieldId['otherXmlFieldsAsJson']] as
            | string
            | undefined
        };
      }

      return undefined;
    })
    .filter(removeNullish);
};

const updateITwoProjectInfo = (
  iTwoXmlJson: XmlDocument,
  projectName?: string,
  projectDescription?: string
) => {
  if (!projectName && !projectDescription) {
    return;
  }

  const estimateRoot = iTwoXmlJson.elements?.[0];
  if (estimateRoot?.type === 'element' && estimateRoot.elements) {
    const projectInfoElement = findXmlElementByName(estimateRoot.elements, 'PrjInfo');
    if (projectInfoElement) {
      projectInfoElement.elements = projectInfoElement.elements?.map(element => {
        if (element.type === 'element') {
          if (element.name === 'NamePrj') {
            return {
              ...element,
              elements: [{ type: 'text', text: projectName! }]
            };
          }
          if (element.name === 'DescrPrj' && projectDescription) {
            return {
              ...element,
              elements: [{ type: 'text', text: projectDescription }]
            };
          }
        }
        return element;
      });
    } else {
      logger.error('PrjInfo element not found in iTWO XML');
    }
  }
};
