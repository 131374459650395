const standardUnits = {
  // Length
  m: ['meter', 'metre', 'mtr', 'Meter'],
  // Area
  'm²': ['m2', 'm^2', 'square meter', 'sq m', 'Quadratmeter'],
  // Volume
  'm³': ['m3', 'm^3', 'cubic meter', 'cbm', 'Kubikmeter'],
  // Mass
  kg: ['kilogram', 'Kilogramm'],
  t: ['ton', 'tonne', 'Tonne'],
  // Volume (liquid)
  l: ['liter', 'litre', 'L', 'Liter'],
  // Time
  h: ['hour', 'std', 'Stunde', 'hour(s)', 'std.', 'h.'],
  // Units
  St: ['piece', 'pcs', 'Stück', 'Einheit', 'Piece', 'Stck'],
  // Energy
  kWh: ['kilowatt hour', 'Kilowattstunde'],
  // Currency
  '€': ['EUR', 'Euro'],
  // Small lengths
  cm: ['centimeter', 'centimetre', 'Zentimeter'],
  mm: ['millimeter', 'millimetre', 'Millimeter'],
  // Speed
  'm/min': ['meter per minute', 'Meter pro Minute'],
  'm/s': ['meter per second', 'Meter pro Sekunde'],
  // Pressure
  bar: ['Bar'],
  // Force
  N: ['Newton'],
  kN: ['kilonewton', 'Kilonewton'],
  // Pressure (Pascal)
  Pa: ['pascal', 'Pascal'],
  MPa: ['megapascal', 'Megapascal'],
  // Distance
  km: ['kilometer', 'Kilometer'],
  // Small mass
  g: ['gram', 'Gramm'],
  // Small volume
  ml: ['milliliter', 'Milliliter'],
  // Pressure
  mbar: ['millibar', 'Millibar'],
  // Energy
  kcal: ['kilocalorie', 'Kilokalorie'],
  J: ['joule', 'Joule'],
  W: ['watt', 'Watt'],
  kW: ['kilowatt', 'Kilowatt'],
  // Flow rates
  'm²/h': ['m2/h', 'square meter per hour', 'Quadratmeter pro Stunde'],
  'm³/h': ['m3/h', 'cubic meter per hour', 'Kubikmeter pro Stunde'],
  // Lump sum
  psch: ['pauschal', 'Pauschal', 'PSCH', 'Pauschale', 'lump sum', 'Lump Sum']
};

const unitMapping: Record<string, string> = Object.entries(standardUnits).reduce(
  (acc, [standard, variants]) => {
    acc[standard] = standard;
    variants.forEach(variant => {
      acc[variant.toLowerCase()] = standard;
    });
    return acc;
  },
  {} as Record<string, string>
);

export const standardizeUnit = (unit: string | null | undefined): string | null => {
  if (!unit) return null;
  const unitTrimmed = unit.trim();
  const normalizedUnit = unitTrimmed.toLowerCase();
  return unitMapping[normalizedUnit] || unitTrimmed;
};
