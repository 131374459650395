import { typeidUnboxed } from 'typeid-js';
import { reservedFieldIds } from '../../types';

export const fieldId = () => typeidUnboxed('field');

export const isReservedFieldId = (
  fieldId: string
): fieldId is (typeof reservedFieldIds)[number] => {
  return reservedFieldIds.includes(fieldId as (typeof reservedFieldIds)[number]);
};

export const mapFieldReferenceIdToId = <TField extends { id: string; referenceId: string }>(
  fields: TField[]
): Record<string, string> => {
  return fields.reduce<Record<string, string>>((acc, field) => {
    acc[field.referenceId] = field.id;
    return acc;
  }, {});
};

export const mapFieldIdToReferenceId = <TField extends { id: string; referenceId: string }>(
  fields: TField[]
): Record<string, string> => {
  return fields.reduce<Record<string, string>>((acc, field) => {
    acc[field.id] = field.referenceId;
    return acc;
  }, {});
};
